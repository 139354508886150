import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported, getToken, onMessage } from 'firebase/messaging';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { SERVICE_WORKER_REGISTER, VAPID_KEY, firebaseConfig } from './utils';
import { FCM_TOKEN, GetNotificationList } from '../Redux/notification';

export const app = initializeApp(firebaseConfig);
const isLoggedIn = localStorage.getItem('isLoggedIn');

const FetchNotification = () => {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    isSupported().then((result) => {
      if (result && isLoggedIn && user) {
        const messaging = getMessaging(app);
        if ('serviceWorker' in navigator) {
          navigator?.serviceWorker
            ?.register(`${SERVICE_WORKER_REGISTER}`)
            .then((registration) => {
              getToken(messaging, {
                serviceWorkerRegistration: registration,
                vapidKey: VAPID_KEY,
              })
                .then((currentToken) => {
                  if (currentToken) {
                    const dataObj = {
                      token: currentToken,
                      userId: user?._id,
                    };
                    localStorage.setItem('fcmToken', currentToken);
                    dispatch(FCM_TOKEN(dataObj));
                  }
                })
                .catch((err) => console.error(err));
            })
            .catch((error) => {
              console.error('ERR__ERR__ERR:', error);
            });
          dispatch(GetNotificationList(1, 5, false));
          // on Message get
          onMessage(messaging, function (payload) {
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
              body: payload.notification.body,
              // icon: `/favicon.ico`,
            };

            try {
              if (!('Notification' in window)) {
              } else if (Notification?.permission === 'granted') {
                let notification = new Notification(notificationTitle, notificationOptions);
                const JsonParse = payload && JSON?.parse(payload?.data?.data);
                const notificationId = JsonParse?.notification_id;
                // console.log(
                //   'payload',
                //   payload,
                //   payload && JSON?.parse(payload?.data?.data),
                //   'notificationId',
                //   notificationId
                // );
                notification.onclick = function (event) {
                  if (notificationId) {
                    dispatch(GetNotificationList(1, 5, false));
                  }
                  event.preventDefault();
                  window.open(payload?.data?.link, '_blank');
                  notification.close();
                };
              }
            } catch (err) {
              console.error(err);
            }
          });
        }
      }
    });
  }, []);
};

export { FetchNotification };
